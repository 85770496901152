import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import Vue from 'vue';
import { useFetchResources, props as fetchResourcesProps } from '~/composables/useFetchResources';
export default Vue.extend({
  props: _objectSpread(_objectSpread({}, fetchResourcesProps()), {}, {
    query: {
      type: Function,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    pageSize: {
      type: Number,
      required: false,
      default: 50
    }
  }),
  setup: function setup(props) {
    var _useFetchResources = useFetchResources(props),
      fetchResources = _useFetchResources.fetchResources,
      pagination = _useFetchResources.pagination,
      resources = _useFetchResources.resources,
      loading = _useFetchResources.loading;
    return {
      fetchResources: fetchResources,
      pagination: pagination,
      resources: resources,
      loading: loading
    };
  },
  computed: {
    enabledFields: function enabledFields() {
      var _this = this;
      return this.getFields().filter(function (field) {
        return _this.fields.includes(field.key);
      });
    }
  },
  methods: {
    getFields: function getFields() {
      return [{
        key: 'sent_at',
        label: this.$t('fields.date')
      }, {
        key: 'organization_name',
        label: this.$t('fields.organization')
      }, {
        key: 'applicant_fullname',
        label: this.$t('fields.applicant')
      }, {
        key: 'applicant_occupation_name',
        label: this.$t('fields.occupation')
      }];
    }
  }
});